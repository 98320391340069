import React, { Component } from "react"
import "../../public/css/style.css"

export default class GuestbookPricingSlice extends Component {
  constructor(props) {
    super()

    this.state = {
      selectedSize: props.data.sizes[0].key,
      selectedSides: props.data.minSpreads,
      selectedMaterialType: props.data.coverTypes[0].key,
    }

    this.currencyMap = {
      USD: "$",
      EUR: "€",
      GBP: "£",
      AUD: "$A",
    }
  }

  render() {
    const sizeElement = this.getSizeElements()
    const coverTypes = this.getCoverTypesElements()
    const price = this.getPrice()

    return (
      <div className="Rtable--col-wrapper Rtable--thead">
        <div style={{ order: "0;" }} className="Rtable-cell size">
          <h3>{this.props.data.name}</h3>
        </div>
        <div style={{ order: "1;" }} className="Rtable-cell format">
          <ul>{sizeElement}</ul>
        </div>
        <div style={{ order: "2;" }} className="Rtable-cell spreads">
          <ul>
            <li>
              <h4 align="center">
                <strong>Sides</strong>
              </h4>
            </li>

            <li className="counter">
              <button
                type="button"
                className="button btn-number dec"
                onClick={this.onLessSidesClicked}
                data-type="minus"
                data-field="spreads-no"
              >
                -
              </button>
              <input
                type="text"
                name="spreads-no"
                id="spreads-no"
                className="input-number"
                disabled
                value={this.state.selectedSides}
                data-ng-model="album.spreadAmount"
              ></input>
              <button
                type="button"
                className="button btn-number inc"
                data-type="plus"
                onClick={this.onMoreSidesClicked}
                data-field="spreads-no"
              >
                +
              </button>
            </li>
          </ul>
        </div>
        <div style={{ order: "3;" }} className="Rtable-cell fabric">
          <ul>{coverTypes}</ul>
        </div>
        <div style={{ order: "4;" }} className="Rtable-cell total">
          <ul>
            <li>
              <p className="price">
                <span className="Text--info">
                  {this.currencyMap[this.props.currency]}
                </span>
                <strong>{price}</strong>
              </p>
            </li>
            <li>
              <button
                type="submit"
                form="#pricing-table"
                className="qodef-btn qodef-btn-solid qodef-dir-aware-hover"
                value="Order"
              >
                <span className="qodef-btn-text" onClick={this.onOrderClicked}>
                  ORDER
                </span>
                <span className="qodef-btn-background-holder">
                  <span
                    className="qodef-btn-background"
                    style={{ top: "-4px; left: 169.717px" }}
                  ></span>
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    )
  }

  getSizeElements = () => {
    return this.props.data.sizes.map(size => {
      let sizeValue =
        this.props.measurement === "INCH"
          ? `${size.inch} inch`
          : `${size.cm} cm`

      return (
        <li className="radio">
          <input
            type="radio"
            name={`formats${this.props.data.name}`}
            value={size.key}
            checked={this.state.selectedSize === size.key}
            onChange={this.onSizeSelected}
            id={this.props.data.name + size.key}
          />
          <label htmlFor={this.props.data.name + size.key}>{sizeValue}</label>
          <div className="check">
            <div className="inside"></div>
          </div>
          <small className="Text--info">{size.type}</small>
        </li>
      )
    })
  }

  getCoverTypesElements = () => {
    let priceList = this.props.prices[this.props.currency][this.props.product]
    let materialPrices = priceList.material[this.state.selectedSize]

    return this.props.data.coverTypes.map(cover => {
      return (
        <li className="radio">
          <input
            type="radio"
            name={`materials${this.props.data.name}`}
            value={cover.key}
            checked={this.state.selectedMaterialType === cover.key}
            onChange={this.onMaterialSelected}
            id={this.props.data.name + cover.key}
          />
          <label htmlFor={this.props.data.name + cover.key}>
            {cover.name}
            <br />
            {materialPrices[cover.key].toString() !== "0" && (
              <span className="Text--info">
                +{this.currencyMap[this.props.currency]}
                {materialPrices[cover.key]}
              </span>
            )}
            {materialPrices[cover.key].toString() === "0" && (
              <span className="Text--info">Included</span>
            )}
          </label>
          <div className="check"></div>
        </li>
      )
    })
  }

  onSizeSelected = event => {
    this.setState({
      selectedSize: event.target.value,
    })
  }

  onMaterialSelected = event => {
    this.setState({
      selectedMaterialType: event.target.value,
    })
  }

  onMoreSidesClicked = () => {
    let sides = this.state.selectedSides
    sides += this.props.data.incrementSpreads

    if (sides > this.props.data.maxSpreads) {
      sides = this.props.data.maxSpreads
    }

    this.setState({ selectedSides: sides })
  }

  onLessSidesClicked = () => {
    let sides = this.state.selectedSides
    sides -= this.props.data.incrementSpreads

    if (sides < this.props.data.minSpreads) {
      sides = this.props.data.minSpreads
    }

    this.setState({ selectedSides: sides })
  }

  onOrderClicked = () => {
    window.open("https://www.etsy.com/shop/AlbumHut", "_blank")
  }

  getPrice = () => {
    let priceList = this.props.prices[this.props.currency][this.props.product]

    let sizePrice =
      priceList.size[this.state.selectedSize][this.state.selectedSides]
    let materialPrice =
      priceList.material[this.state.selectedSize][
        this.state.selectedMaterialType
      ]

    return (sizePrice + materialPrice).toFixed(2)
  }
}
